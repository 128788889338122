/* top */
#top{
	display: block;
	min-height: auto
}
#top + .section{
	padding-top: 16em
}
@media only screen and (max-width : 1023px){
    #top + .section{
		padding-top: 4em
	}
}

/* cover */
#cover{
	background: none;
	padding-top: 0;
	overflow: initial;
	z-index: 4
}
#cover,
#cover .section-inner p{
	text-align: left
}
#cover .cols .col:nth-child(2){
	text-align: right
}
#cover .phone{
	display: inline-block;
	margin-bottom: -17.125em
}
#cover h1{
	margin-bottom: 1.5rem
}
#cover .phone{
	position: relative;
	max-width: 356px;
	z-index: 1
}
.sports-equip{
	display: block;
	position: absolute;
	top: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover
}
.se-volleyball{
	width: 235px;
	height: 235px;
	background-image: url(/img/cover-elements/volleyball.png);
	transform: translateY(-15%)
}
.se-football{
	right: 0;
	width: 186px;
	height: 186px;
	background-image: url(/img/cover-elements/football.png);
	transform: translateX(150%) translateY(10%)
}
.se-crown{
	top: 40%;
	width: 195px;
	height: 221px;
	background-image: url(/img/cover-elements/crown.png);
	transform: translateX(-25%)
}
.se-running{
	top: 40%;
	right: 0;
	width: 133px;
	height: 128px;
	background-image: url(/img/cover-elements/run.png);
	transform: translateX(125%)
}
.se-baseball{
	top: 90%;
	width: 105px;
	height: 105px;
	background-image: url(/img/cover-elements/baseball.png);
	transform: translateX(150%)
}
.se-basketball{
	top: 75%;
	right: 0;
	width: 256px;
	height: 256px;
	background-image: url(/img/cover-elements/basketball.png);
	transform: translateX(150%)
}
.se-rugby{
	top: 100%;
	width: 198px;
	height: 198px;
	background-image: url(/img/cover-elements/rugby.png);
	transform: translateX(-50%)
}
.se-golf{
	top: 100%;
	right: 0;
	width: 92px;
	height: 92px;
	background-image: url(/img/cover-elements/golf.png);
	transform: translateX(125%)
}
.ball-shadow:before{
	content: '';
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	box-shadow: 81px 81px 92px rgba(6, 20, 66, .39)
}
@media only screen and (max-width : 1439px){
	#cover{
		padding-top: 4em
	}
}
@media only screen and (min-width : 1024px){
	#cover .cover-img{
		display: none
	}
}
@media only screen and (max-width : 1023px){
	#cover{
		padding-top: 0;
		padding-right: 0;
		padding-left: 0
	}
	#cover,
	#cover .section-inner p{
		text-align: center
	}
	#cover .cols .col{
		padding: 0 2em
	}
	#cover .cols .col:nth-child(2){
		padding: 0
	}
	#cover img{
		max-width: 100%;
		margin-bottom: 0
	}
	#cover .sports-equips,
	#cover .phone{
		display: none
	}
	#cover .cover-img{
		width: 100%
	}
}

/* find-meetups */
#find-meetups{
	background: #fff;
	z-index: 3
}

/* digital-rewards */
.reward{
	margin-bottom: 10.75em
}
.reward-medal:after{
	width: 954px;
	height: 954px;
	background-image: url(/img/rewards/rewards-bg.png)
}
@media only screen and (max-height : 1023px){
    .reward-medal:after{
        width: 660px;
        height: 660px
    }
}
@media only screen and (max-width : 767px){
	.reward{
		margin-bottom: 3em
	}
    .reward-medal:after{
        width: 340px;
        height: 340px
    }
}